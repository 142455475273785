import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor() { }

  public projectsList = [
    {
      name : "fournishop" ,
      imgs_path : 'assets/imgs/fournishop/' ,
      background : "background.png" ,
      imgs : ["resp1.png" ,"resp2.png"]
    } ,
    {
      name : "esi" ,
      imgs_path : 'assets/imgs/esi/' ,
      background : "background.png" ,
      imgs : ["resp1.png" ,"resp2.png"]
    } ,
    {
      name : "sits" ,
      imgs_path : 'assets/imgs/sits/' ,
      background : "background.png" ,
      imgs : ["resp1.png" ,"resp2.png"]
    } ,
    {
      name : "dictili" ,
      imgs_path : 'assets/imgs/dictili/' ,
      background : "background.png" ,
      imgs : ["resp1.png" ,"resp2.png"]
    } ,
    {
      name : "portfolio" ,
      imgs_path : 'assets/imgs/portfolio/' ,
      background : "background.png" ,
      imgs : ["resp1.png" ,"resp2.png"]
    } ,
    {
      name : "tchatchet" ,
      imgs_path : 'assets/imgs/tchatchet/' ,
      background : "background.png" ,
      imgs : ["log.png" ,"1.png" , "2.png" , '3.png']
    }
  ]
}
