import { Component, OnInit } from '@angular/core';
import * as $   from 'jquery' ;
import { TextAnimation } from 'ngx-teximate';
import { fadeInLeft , fadeInRight , fadeInDown , fadeInUp  } from 'ng-animate';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'home';
  shown_sidenav_scroll = 'hover' ;
  opennedSidenav = false ;
  f_min = false ;
  show_loading = false ;
  constructor(private router : Router) {
    this.router.events.subscribe((routerEvent : Event)=> {

      if (routerEvent instanceof NavigationEnd) {
        this.show_loading = false ;
        this._hideLoader() ;
      }
    })
  }

  toggleSidenav() {
    this.opennedSidenav = !this.opennedSidenav ;
  }
  ngOnInit(){
    if ($(window).width() <= 576 ) {
      this.opennedSidenav = false ;
      this.f_min = false ;
    }else{
      this.opennedSidenav = true ;
      this.f_min = true ;
    }

    $(window).resize(()=>{
      if ($(window).width() <= 576 && this.f_min) {
        this.f_min = false ;
        this.opennedSidenav = false ;
      }else if($(window).width() > 576 && !this.f_min) {
        this.f_min = true ;
        this.opennedSidenav = true ;
      }
    });

    if (location.pathname != "/") {
      $(".content_not_loading").fadeOut(0) ;
      $(".loading_page_div").css("display" , "inline-block") ;
      $(".loading_div").css({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"}) ;
      $(".loading_content").css("display" , "inline-block") ;
    }
    switch (location.pathname) {
      case "/skills":
        $(".activeItem").removeClass('activeItem') ;
        $(".skillsSidenav").addClass('activeItem') ;
        break;
      case "/about":
        $(".activeItem").removeClass('activeItem') ;
        $(".aboutSidenav").addClass('activeItem') ;
        break;
      case "/contact":
        $(".activeItem").removeClass('activeItem') ;
        $(".contactSidenav").addClass('activeItem') ;
        break;
      case "/myWork":
        $(".activeItem").removeClass('activeItem') ;
        $(".workSidenav").addClass('activeItem') ;
        break;


      default:
        break;
    }
  }


  _showLoader(){
    $(".content_not_loading").fadeOut(0) ;
     $(".loading_page_div").fadeIn(200) ;
     $(".loading_content").fadeIn(200) ;
      setTimeout(()=>{
        $(".loading_div").animate({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"} , 500 , function(){
        })
      } , 500) ;
  }
  _hideLoader(){
    setTimeout(()=>{
      if (!this.show_loading) {
        $(".loading_content").fadeOut(400) ;
        setTimeout(()=>{
          $(".loading_div").animate({"width" : "200px" , "height" : "200px" , "border-radius" : "50%"} , 500 , function(){
            $(".loading_page_div").fadeOut(500) ;
            $(".content_not_loading").fadeIn(0) ;
          })
        } , 800) ;
      }

    } , 800) ;
  }

  GoPage(data) {
    console.log(location.hash) ;
    switch (data) {
      case 'skills':
        $(".activeItem").removeClass('activeItem') ;
        $(".skillsSidenav").addClass('activeItem') ;
        if (location.hash != '#/skills') {
          this._showLoader() ;
          setTimeout(()=>{
            this.router.navigate(['skills']) ;
          } , 1000) ;
        }

        break;
      case 'home':
        $(".activeItem").removeClass('activeItem') ;
        $(".homeSidenav").addClass('activeItem') ;
        if (location.hash != '#/') {
          this._showLoader() ;
          setTimeout(()=>{
            this.router.navigate(['']) ;
          } , 1000) ;
        }
        break;

      case 'about':
        $(".activeItem").removeClass('activeItem') ;
        $(".aboutSidenav").addClass('activeItem') ;
        if (location.hash != '#/about') {
          this._showLoader() ;
          setTimeout(()=>{
            this.router.navigate(['about']) ;
          } , 1000) ;
        }
        break;

      case 'contact':
        $(".activeItem").removeClass('activeItem') ;
        $(".contactSidenav").addClass('activeItem') ;
        if (location.hash != '#/contact') {
          this._showLoader() ;
          setTimeout(()=>{
            this.router.navigate(['contact']) ;
          } , 1000) ;
        }
        break;

      case 'work':
      $(".activeItem").removeClass('activeItem') ;
      $(".workSidenav").addClass('activeItem') ;
      if (location.hash != '#/myWork') {
        this._showLoader() ;
        setTimeout(()=>{
          this.router.navigate(['myWork']) ;
        } , 1000) ;
      }
      break;

      default:
        break;
    }

    if ($(window).width() <= 576 ) {
      this.toggleSidenav() ;
    }
  }

  click_fb() {
    window.open('https://www.facebook.com/abdou.badeche') ;
  }

  click_gh() {
    window.open('https://github.com/abdouBadeche') ;
  }

  click_in() {
    window.open('https://www.linkedin.com/in/badeche-abdennour/') ;
  }


}
