import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path : '' , component : HomeComponent
  } ,
  {
     path: 'contact', loadChildren: ()=>import('./contact/contact.module').then(m => m.ContactModule  )

  } ,
  {
     path: 'about', loadChildren: ()=>import('./about/about.module').then(m => m.AboutModule  )

  } ,
  {
     path: 'skills', loadChildren: ()=>import('./skills/skills.module').then(m => m.SkillsModule  )

  } ,
  {
     path: 'myWork', loadChildren: ()=>import('./work/work.module').then(m => m.WorkModule  )

  } ,
  { path: "**", redirectTo: "", pathMatch: "full" },


];

@NgModule({
  imports: [RouterModule.forRoot(routes , {useHash : true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
