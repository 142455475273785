import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import {MatSidenavModule} from '@angular/material/sidenav' ;
import {MatToolbarModule} from '@angular/material/toolbar' ;

import { TeximateModule } from 'ngx-teximate';
import { HomeComponent } from './home/home.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ProjectsService } from './projects.service';


import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule } from '@angular/material/button' ;
import {MatDialogModule} from '@angular/material/dialog';
import { PhotoComponent } from './photo/photo.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PhotoComponent ,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot() ,
    MatToolbarModule ,
    MatSidenavModule ,
    TeximateModule ,
    NgScrollbarModule ,
    MatSliderModule ,
    MatProgressBarModule ,
    MatIconModule ,
    MatButtonModule ,
    MatDialogModule

    ],
  entryComponents: [

  ],
  providers: [
    ProjectsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
